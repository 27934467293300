import * as React from "react";
import { GlobalStyle } from "../GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/Footer/Footer";
import ThreeStages from "../components/ThreeStages/Threestages"
import { Helmet } from "react-helmet";
// markup
const IndexPage = () => {



  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet" />
      </Helmet>
      <GlobalStyle />
      <Navbar />

      <ThreeStages
      Content={(<>
        <h1 className={"text-3xl"}>Disculpe parece que esa página no existe</h1>
        <h1 className={"text-5xl"}>ERROR 404</h1>
        </>
      )}
      />


      <Footer />
    </>

  )
};

export default IndexPage
